import * as React from "react"
import Input from "../components/input.js"
// import Submit from "../components/Submit.js"
import SubmitSearch from "../components/SubmitSearch.js"

export default function Search({hasTitle = false}) {
  return (
    <div className="mt-8 mt-lg-0">
      {
        hasTitle
        ?
          <h3 className="fw-700 mb-2r h4">Busca</h3>
        :
          null
      }
      <form action="/busca">
        <div className="d-xl-flex">
          <Input name="s" noLabel placeholder="Pesquisar no blog..." required fieldsetClass="w-100 mb-2 mb-xl-0 me-xl-2" inputClass="w-100" />
          <SubmitSearch label="Pesquisar" hasIcon fill="white" className="ms-auto size-p btn bg-color-purple" />
        </div>
      </form>    
    </div>
  )
}