import * as React from 'react'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { graphql } from 'gatsby'

import Container from '../helpers/Container.js'
import ContainerWithBg from '../helpers/ContainerWithBg.js'

import Page from '../elements/Page.js'
import BlogPost from '../elements/BlogPost.js'

import Sidebar from '../section/Sidebar.js'
import { Pagination } from '../components/Pagination.js'

export default class ConteudoPage extends React.Component {
  render() {
    const { currentPage, postsNumPages } = this.props.pageContext
    const { data } = this.props

    const seoSchema = data.wp?.seo?.contentTypes?.post?.schema?.raw ?? undefined

    return (
      <Page>
        <Seo
          title={
            currentPage === 1
              ? 'Blog Comunicação Integrada'
              : `Blog Comunicação Integrada - página ${currentPage}`
          }
          postSchema={seoSchema ? JSON.parse(seoSchema) : undefined}
        />
        <ContainerWithBg
          classBgName="bg-pattern bg-image-blog-hero"
          className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1 feature-image"
        >
          <span className="tag bg-color-golden c-purple m-2 c-p db ttu mb-2 bold">
            Blog
          </span>
          <h1 className="h1 mb-0">Comunicação Integrada</h1>
        </ContainerWithBg>
        <Container className="container-xg mt-4r mb-4r">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8">
                <div className="pe-lg-8">
                  <h2 className="c-purple h2 mb-3r">Notícias</h2>

                  <div className="row">
                    {data.allWpPost.nodes.map((node) => (
                      <div
                        className="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 d-flex justify-content-center align-items-stretch mb-4"
                        key={node.id}
                      >
                        <BlogPost
                          tag={node.categories.nodes[0].name}
                          date={node.date}
                          toPath={node.link}
                          toPathTag={node.categories.nodes[0].link}
                          title={node.title}
                          excerpt={node.excerpt}
                          imageSrc={
                            node.featuredImage ? node.featuredImage.node : null
                          }
                          classInner="blog-index"
                        />
                      </div>
                    ))}
                  </div>
                  <Pagination
                    postsNumPages={postsNumPages}
                    currentPage={currentPage}
                    base="/blog/"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <Sidebar />
              </div>
            </div>
          </div>
        </Container>
      </Page>
    )
  }
}

export const blogListQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        excerpt
        content
        slug
        link
        date(formatString: "D/M/y")
        categories {
          nodes {
            link
            name
          }
        }
        author {
          node {
            nicename
            firstName
            lastName
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`
