import * as React from "react"
import Search from "../elements/Search.js"
import LatestPosts from "../elements/LatestPosts.js"
import Categories from "../elements/Categories.js"
import Newsletter from "../elements/Newsletter.js"
import MateriaisEducativosCta from "../elements/MateriaisEducativosCta.js"

export default function Sidebar() {
  return (
    <div className="pt-5">
      <div className="row">
        <div className="order-2 order-lg-1 col-12">
          <Search />
        </div>
        <div className="order-1 order-lg-2 col-12">
          <Newsletter />
        </div>
        <div className="order-3 order-lg-3 col-sm-6 col-lg-12">
          <LatestPosts />
        </div>
        <div className="order-4 order-lg-4 col-sm-6 col-lg-12">
          <Categories />
        </div>
        <div className="order-5 order-lg-5 col">
          <MateriaisEducativosCta />
        </div>
      </div>
    </div>
  )
}