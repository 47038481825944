import * as React from "react"
import { Link } from "gatsby"

function renderLink(link, label) {
  return (
    <Link to={link} className="page-link">
      { label }
    </Link>
  )
}

function renderItem(currentPage, i, base) {
  return (
    <li 
      className={`page-item ${i === currentPage-1 ? "active" : "" }`}
      key={`pagination-number${i + 1}`}
    >
      { renderLink(`${base}${i === 0 ? "" : i + 1}`, i + 1) }
    </li>
  )
}

export function Pagination({postsNumPages, currentPage, base}) {
  
  if(postsNumPages <= 1) return (<div></div>)

  if(postsNumPages <= 5) {
    return (
      <ul className="pagination justify-content-center round">
        { 
          Array.from({ length: postsNumPages }, (_, i) => (
            renderItem(currentPage, i, base)
          ))
        }
      </ul>
    )
  } 

  const limiter = 3
  const init = (currentPage < limiter) ? [0,1,2] : [0]
  const middle = (currentPage >= limiter && currentPage <= (postsNumPages - limiter))
     ? [currentPage-2,currentPage-1,currentPage] : []
  const last = (currentPage >= limiter && currentPage <= (postsNumPages - limiter))
     ? [postsNumPages-1] : (
      (currentPage === (postsNumPages - limiter + 1))
       ? [postsNumPages-4,postsNumPages-3,postsNumPages-2, postsNumPages-1]
       : [postsNumPages-3,postsNumPages-2,postsNumPages-1]
     )
  
  return (
    <ul className="pagination justify-content-center round">
      { //initial pages
        init.map( (node)=> (
          renderItem(currentPage, node, base)
        ))
      }
      <li className="page-item d-flex"><span className="db page-link">...</span></li>
      { //middel pages
        middle.map( (node)=> (
          renderItem(currentPage, node, base)
        ))
      }
      {last.length === 1 && ( `...` ) }
      { // last pages
        last.map( (node)=> (
          renderItem(currentPage, node, base)
        ))
      }
    </ul>
  )
  
} 